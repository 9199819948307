/*******************************************************************************
 * Style overrides for distill blog posts.
 ******************************************************************************/

d-byline {
  border-top-color: $grey-color-light !important;
}

d-byline h3 {
  color: var(--global-text-color) !important;
}

d-byline a, d-article d-byline a {
  color: var(--global-text-color) !important;
  &:hover {
    color: var(--global-hover-color) !important;
  }
}

d-article {
  border-top-color: #e8e8e8 !important;
  a, p, h1, h2, h3, h4, h5, h6, li, table {
    color: var(--global-text-color) !important;
  }
  a, h1, h2, hr {
    border-bottom-color: $grey-color-light !important;
  }
  a:hover {
    border-bottom-color: var(--global-hover-color) !important;
  }
}

d-appendix {
  border-top-color: $grey-color-light !important;
  color: var(--global-distill-app-color) !important;
  h3, li, span {
    color: var(--global-distill-app-color) !important;
  }
  a, a.footnote-backlink {
    color: var(--global-distill-app-color) !important;
    &:hover {
      color: var(--global-hover-color) !important;
    }
  }
}
